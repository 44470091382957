import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Table, Input } from "antd";
import { MdArrowBack } from "react-icons/md";
import { useGetProductMutation } from "../../redux/slices/productSlice";

const FilterProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const hasRunEffect = useRef(false);
    const [productData, setproductData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { categoryId } = location.state || {};
    const [getProduct, { isLoading }] = useGetProductMutation();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const tableColumns = [
        {
            title: "S.No.",
            key: "serial",
            render: (_, __, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            },
        },
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            render: (text, record) => (
                <div className="productBox" style={{ display: "flex", alignItems: "center" }}>
                    <div className="imgBox" style={{ marginRight: "20px" }}>
                        {record.imageUrl ? (
                            <img
                                src={record.imageUrl}
                                alt={record.name}
                                className="img-fluid"
                                style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px" }}
                            />
                        ) : (<p className="prouct-image-text">Product Image</p>)}

                    </div>
                    <span style={{ textTransform: 'capitalize', fontWeight: '600' }}>{record.name}</span>
                </div>
            ),
        },
        {
            title: "Company name",
            dataIndex: "company",
            key: "company",
        },
        {
            title: "Vendor name",
            dataIndex: "vendor",
            key: "vendor",
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            // sorter: (a, b) => a.price.length - b.price.length,
        },
        {
            title: "In Stock",
            dataIndex: "stock",
            key: "stock",
            // sorter: (a, b) => a.stock - b.stock,
        },
    ];
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleNavigate = (record) => {
        navigate("/product-details", {
            state: { productId: record.key },
        });
    };
    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        const query = `?per_page=-1`
        getProduct(query)
            .then((res) => {
                const filtereddata = res.data.filter(
                    (item) => item.category_id === categoryId
                );
                const formattedData = filtereddata.map((item) => ({
                    key: item.id,
                    imageUrl: item.image,
                    name: item.item_name,
                    vendor: item.vendor_name ? item.vendor_name : 'N/A',
                    company: item.company_name ? item.company_name : "N/A",
                    category: item.category.name,
                    price: `$${item.price}`,
                    stock: item.quantity,
                }));
                setproductData(formattedData);
                setFilteredData(formattedData);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }, []);
    const handleProductSearch = (event) => {
        const searchText = event.target.value.toLowerCase().trim();

        const filteredData = productData.filter((item) =>
            item.name.toLowerCase().includes(searchText) ||
            item.vendor.toLowerCase().includes(searchText) ||
            item.company.toLowerCase().includes(searchText) ||
            item.category.toLowerCase().includes(searchText)

        );
        setFilteredData(filteredData);
    };

    const handlePaginationChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
        });
    };

    const onPageSizeChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
        });
    };

    return (
        <div className="wrapper productCatalogWrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Products</h4>
                            <div className="rightWrapper">
                                <Input
                                    placeholder="Search here"
                                    onChange={handleProductSearch}
                                    className="homesearchInput titleRowInput"
                                />
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleGoBack()}
                                >
                                    <MdArrowBack /> Back
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredData}
                                pagination={{
                                    current: pagination.current,
                                    pageSize: pagination.pageSize,
                                    onChange: handlePaginationChange,
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                }}
                                loading={isLoading}
                                onRow={(record) => ({
                                    onClick: () => handleNavigate(record),
                                })}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterProducts;