import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Url } from "../../Helper";
import Logout from "../../LogoutHelper";
const commonHeaders = {
  "Content-Type": "application/json; charset=UTF-8",
  Accept: "application/json",
};

const customBaseQuery = fetchBaseQuery({
  baseUrl: Api_Url,
  prepareHeaders: (headers,{ getState }) => {
    const token = getState()?.persistedReducer?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await customBaseQuery(args, api, extraOptions);
  if (result?.error && result?.error?.status === 401) {
    Logout();
  }
  return result;
};


export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    userRegister: builder.mutation({
      query: (newUser) => ({
        url: "/register",
        method: "POST",
        body: newUser,
        headers: commonHeaders,
      }),
    }),

    userLogin: builder.mutation({
      query: (userDetails) => ({
        url: "/login",
        method: "POST",
        body: userDetails,
        headers: commonHeaders,
      }),
    }),

    userProfile: builder.mutation({
      query: ({user,id}) => ({
        url: `user/${id}/update-profile`,
        method: "POST",
        body: user,
      }),
    }),
    
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/forgot-password",
        method: "POST",
        body: email,
        headers: commonHeaders,
      }),
    }),

    userDelete: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
        headers: commonHeaders,
      }),
    }),
    getRegisteredUsers: builder.mutation({
      query: (value) => ({
        url: `/users${value}`,
        method: "GET",
        headers: commonHeaders,
      }),
    }),
    updateUserStatus: builder.mutation({
      query: ({Id,value}) => ({
        url: `/users/${Id}/status`,
        method: "PUT",
        headers: commonHeaders,
        body:value
      }),
    }),
    sendUserInvite: builder.mutation({
      query: ({value}) => ({
        url: `/send-invite`,
        method: "POST",
        headers: commonHeaders,
        body:value
      }),
    }),
    UpdatePassword: builder.mutation({
      query: ({value}) => ({
        url: `/update-password`,
        method: "POST",
        headers: commonHeaders,
        body:value
      }),
    }),
    getSettingDetails: builder.mutation({
      query: () => ({
        url: `/order_settings`,
        method: "GET",
        headers: commonHeaders,
      }),
    }),

    updateShippingDetails: builder.mutation({
      query: (value) => ({
        url: `/settings/shipping-details`,
        method: "PUT",
        headers: commonHeaders,
        body:value
      }),
    }),

    updatePrescriberDetails: builder.mutation({
      query: (value) => ({
        url: `/settings/prescriber-details`,
        method: "PUT",
        headers: commonHeaders,
        body:value
      }),
    }),

    resendInviteUser: builder.mutation({
      query: (value) => ({
        url: `/resend-invite`,
        method: "POST",
        headers: commonHeaders,
        body:value
      }),
    }),

    chnageUserRole: builder.mutation({
      query: (value) => ({
        url: `/user/change_role`,
        method: "POST",
        headers: commonHeaders,
        body:value
      }),
    }),

  }),
});

export const {useUserRegisterMutation, useUserLoginMutation,useUserProfileMutation, useForgotPasswordMutation , useUserDeleteMutation, useGetRegisteredUsersMutation, useUpdateUserStatusMutation, useSendUserInviteMutation, useUpdatePasswordMutation, useGetSettingDetailsMutation, useUpdateShippingDetailsMutation, useUpdatePrescriberDetailsMutation, useResendInviteUserMutation, useChnageUserRoleMutation } = authApi;
