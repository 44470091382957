import React, { useState, useEffect } from "react";
import { Table, Spin, Modal, Button } from "antd";
import moment from 'moment';
import {
    useApproveBulkOrderMutation,
    useDenyBulkOrderMutation,
    useGetBulkOrderMutation
} from "../../redux/slices/productSlice";
import Swal from "sweetalert2";

const BulkPendingOrders = () => {
    const [getPendingOrder, { isLoading: isPendingOrderLoading }] = useGetBulkOrderMutation();
    const [orderData, setorderData] = useState([]);
    const [approveOrder, { isLoading: isApproveOrderLoading }] = useApproveBulkOrderMutation();
    const [denyOrder, { isLoading: isDenyOrderLoading }] = useDenyBulkOrderMutation();
    const [recordId, setRecordId] = useState(null)
    const [detailModal, setDetailModal] = useState(false)
    const [patientDetails, setPatientDetails] = useState([])
    const [pendingPerPage, setPendingPerPage] = useState(10)
    const [pendingPage, setPendingPage] = useState(1)
    const [pendingTotal, setPendingTotal] = useState(0)
    let recordData = null;
    useEffect(() => {
        const query = `?per_page=${pendingPerPage}&page=${pendingPage}&type=pending`;
        getOrderData(query)
    }, [pendingPerPage, pendingPage]);

    const ActionLoading = isApproveOrderLoading || isDenyOrderLoading;


    const getOrderData = (query) => {
        getPendingOrder(query)
            .then((res) => {
                setorderData(res?.data?.data);
                setPendingTotal(res?.data?.pagination?.total)
            })
            .catch((err) => {
                console.error("err", err);
            });
    }

    const handleOrderData = (data) => {
        const recordData = data?.bulk_order_id;
        setRecordId(recordData);
        const patientOrderIds = data?.patients?.map((item) => item?.order_id);

        approveOrder({ order_ids: patientOrderIds })
            .then((res) => {
                Swal.fire({
                    title: "Order Approved Successfully",
                    text: `Bulk order has been approved successfully.`,
                    icon: "success",
                });
                const query = `?per_page=${10}&page=${1}&type=pending`;
                getOrderData(query);
            })
            .catch((err) => {
                const query = `?per_page=${10}&page=${1}&type=pending`;
                getOrderData(query);
            })
            .finally(() => {
                setRecordId(null);
            });
    };

    const handleOrderDeny = (data) => {
        const recordData = data?.bulk_order_id;
        setRecordId(recordData);
        const patientOrderIds = data?.patients?.map((item) => item?.order_id);

        Swal.fire({
            title: "Add reason",
            text: "Add reason for order deny.",
            input: 'textarea',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = {
                    order_ids: patientOrderIds,
                    denial_reason: result?.value || "",
                };
                denyOrder(formData)
                    .then((response) => {
                        Swal.fire({
                            title: "Order Denied Successfully",
                            text: `Order has been denied successfully.`,
                            icon: "success",
                        });
                        const query = `?per_page=${10}&page=${1}&type=pending`;
                        getOrderData(query).catch((err) => {
                            console.error("err", err);
                        });
                    })
                    .catch((err) => {
                        const query = `?per_page=${10}&page=${1}&type=pending`;
                        getOrderData(query);
                        console.warn({ err });
                    })
                    .finally(() => {
                        setRecordId(null);
                    });
            } else if (result.isDismissed) {
                console.log("Order denial was canceled.");
                setRecordId(null);
            }
        });
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(pendingTotal);

    const Patientdetailscolumn = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (_, record, index) => (
                <>
                    {(pendingPage - 1) * pendingPerPage + index + 1}
                </>
            ),
        },
        {
            title: "Medicine",
            dataIndex: "medicine",
            key: "medicine",
            render: ((text, record) => (
                <>
                    <span>{record?.medicine?.medicine_name}</span>
                </>
            ))
        },
        {
            title: "Order Quantity",
            dataIndex: "orderQuantity",
            key: "orderQuantity",
            render: ((text, record) => (
                <>
                    <span>{record?.total_order_quantity}</span>
                </>
            ))
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: ((text, record) => (
                <>
                    <span>${record?.total_amount}</span>
                </>
            ))
        },
        {
            title: "Days",
            dataIndex: "days",
            key: "days",
            render: ((text, record) => (
                <>
                    <span>{record?.days}</span>
                </>
            ))
        },
        {
            title: "Delivery Address",
            dataIndex: "deliveryAddress",
            key: "deliveryAddress",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.new_shipping_address ? record?.data?.order?.new_shipping_address : record?.shipping_details?.addressLine1 ? record?.shipping_details?.addressLine1 : "N/A"}</span>
                </>
            ))
        },
        {
            title: "Order date",
            dataIndex: "order_date",
            key: "order_date",
            render: ((text, record) => (
                <>
                    <span>{record?.bulk_order_date}</span>
                </>
            ))
        },
        {
            title: "Order Details",
            dataIndex: "info",
            key: "info",
            render: ((text, record) => (
                <>
                    <Button className="view-button" onClick={() => { setDetailModal(true); setPatientDetails(record) }}>
                        View Details
                    </Button>
                </>
            ))
        },
        {
            title: "Update Order",
            dataIndex: "update_order",
            key: "update_order",
            render: ((text, record) => {
                return (
                    <div className='action-button'>
                        {ActionLoading && recordId === record?.bulk_order_id ? (
                            <Spin />
                        ) : (
                            <>
                                <button className='approve-button' onClick={() => handleOrderData(record)}>
                                    {'Approve'}
                                </button>
                                <button className='deny-button' onClick={() => handleOrderDeny(record)}>
                                    {'Deny'}
                                </button>
                            </>
                        )}
                    </div>
                );
            })
        },

    ];

    const BulkOrderColumn = [
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            key: "patient_name",
            render: ((text, record) => (
                <>
                    <span>{record?.patient_name}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            render: ((text, record) => (
                <>
                    <span>{record?.last_date_ordered ? moment(record.last_date_ordered).format('MM/DD/YY hh:mm:ss A') : "N/A"}</span>

                </>
            ))

        },
        {
            title: "Order Quantity",
            dataIndex: "orderQuantity",
            key: "orderQuantity",
            render: ((text, record) => (
                <>
                    <span>{record?.quantity ? record?.quantity : "N/A"}</span>
                </>
            ))

        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: ((text, record) => (
                <>
                    <span>${record?.amount}</span>
                </>
            ))

        },
        {
            title: "Patient Address",
            dataIndex: "address",
            key: "address",
            render: ((text, record) => (
                <>
                    <span>{record?.address && record?.address !== "" ? record?.address : "N/A"}</span>
                </>
            ))

        },
    ];

    const onPageSizeChange = (current, size) => {
        setPendingPerPage(size);
        setPendingPage(1);
    };


    return (
        <>
            <div className="wrapper orderWrapper">
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="titleBar d-flex justify-content-between">
                                <h4>Bulk Pending Orders</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="card tableCard">
                            <div className="card-body">
                                <div className="tableWrapper registered-user-table">
                                    <Table
                                        columns={Patientdetailscolumn}
                                        dataSource={orderData}
                                        pagination={{
                                            current: pendingPage,
                                            pageSize: pendingPerPage,
                                            total: pendingTotal,
                                            onChange: (page) => setPendingPage(page),
                                            onShowSizeChange: onPageSizeChange,
                                            showSizeChanger: true,
                                            pageSizeOptions: pageSizeOptions,
                                        }}
                                        bordered
                                        className="mb-4 customOrderTable productTable"
                                        loading={isPendingOrderLoading}
                                        scroll={{ x: 'auto' }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Order Details"
                centered
                open={detailModal}
                onOk={() => { setDetailModal(false); setPatientDetails([]) }}
                onCancel={() => { setDetailModal(false); setPatientDetails([]) }}
                className="bulk-order-modal"
                footer={false}
                width={900}
            >
                <div className="tableWrapper registered-user-table mt-4 mb-4">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Name:</label> {patientDetails?.data?.order?.rxs[0]?.drugName ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Strength:</label> {patientDetails?.data?.order?.rxs[0]?.drugStrength ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Form:</label> {patientDetails?.data?.order?.rxs[0]?.drugForm ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Quantity Unit:</label> {patientDetails?.data?.order?.rxs[0]?.quantityUnits ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Total Amount:</label> ${patientDetails?.total_amount ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Clinical Statement:</label> {patientDetails?.data?.order?.rxs[0]?.clinicalDifferenceStatement ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Directions:</label> {patientDetails?.data?.order?.rxs[0]?.directions ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Shipping Address:</label> {patientDetails?.data?.order?.new_shipping_address ? patientDetails?.data?.order?.new_shipping_address : patientDetails?.shipping?.addressLine1 ?? "N/A"}</div>
                        </div>
                    </div>
                </div>
                <div className="tableWrapper registered-user-table">
                    <Table
                        columns={BulkOrderColumn}
                        dataSource={patientDetails?.patients}
                        pagination={true}
                        bordered
                        className="mb-4 customOrderTable productTable"
                        loading={false}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default BulkPendingOrders;
