import React, { useState, useEffect } from 'react';
import { Table, Input, Modal, Button, Tag } from "antd";
import {
	useGetBulkOrderMutation
} from "../../redux/slices/productSlice";
import { IoMdInformationCircleOutline } from "react-icons/io";
import moment from 'moment';

export default function BulkOrderList() {
	const [orderData, setorderData] = useState([]);
	const [orderTotal, setOrderTotal] = useState(0);
	const [orderPerPage, setOrderPerPage] = useState(10);
	const [orderPage, setOrderPage] = useState(1);
	const [orderSearch, setOrderSearch] = useState('');
	const [detailModal, setDetailModal] = useState(false)
	const [patientDetails, setPatientDetails] = useState([])

	const [getOrder, { isLoading: isLoadinggetOrder }] = useGetBulkOrderMutation();

	const generatePageSizeOptions = (total) => {
		const options = [];
		const maxPageSize = Math.min(total, 100);
		for (let i = 5; i <= maxPageSize; i += 5) {
			options.push(i);
		}
		if (total > 5 && !options.includes(10)) {
			options.push(10);
		}

		if (total > 10 && !options.includes(15)) {
			options.push(15);
		}
		return options.sort((a, b) => a - b);
	};

	const pageSizeOptions = generatePageSizeOptions(orderTotal);

	const handleOrderSearch = (event) => {
		setOrderPage(1)
		setOrderPerPage(10)
		setOrderSearch(event?.target?.value)
	};

	const getOrderData = (query) => {
		getOrder(query)
			.then((res) => {
				setorderData(res?.data?.data)
				setOrderTotal(res?.data?.pagination?.total_items)
			})
			.catch((err) => {
				console.error("err", err);
			});
	}

	useEffect(() => {
		let query = `?per_page=${orderPerPage}&page=${orderPage}`
		if (orderSearch !== '') {
			query += `&search=${orderSearch}`
		}
		getOrderData(query)
	}, [orderPerPage, orderPage, orderSearch]);

	const Patientdetailscolumn = [
		{
            title: "S.No",
            key: "serial_number",
            render: (text, record, index) =>
               <span>{(orderPage - 1) * orderPerPage + index + 1}</span>,
        },
		{
			title: "Drug name",
			dataIndex: "medicine",
			key: "medicine",
			render: ((text, record) => (
				<>
					<span>{record?.medicine?.medicine_name}</span>
				</>
			))
		},
		{
			title: "Order Quantity",
			dataIndex: "orderQuantity",
			key: "orderQuantity",
			render: ((text, record) => (
				<>
					<span>{record?.total_order_quantity}</span>
				</>
			))
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			render: ((text, record) => (
				<>
					<span>${record?.total_amount}</span>
				</>
			))
		},
		{
			title: "Days",
			dataIndex: "days",
			key: "days",
			render: ((text, record) => (
				<>
					<span>{record?.days_supply}</span>
				</>
			))
		},
		// {
		// 	title: "Delivery Address",
		// 	dataIndex: "deliveryAddress",
		// 	key: "deliveryAddress",
		// 	render: ((text, record) => (
		// 		<>
		// 			<span>{record?.delivery_address}</span>
		// 		</>
		// 	))
		// },
		{
			title: "Order date",
			dataIndex: "order_date",
			key: "order_date",
			render: ((text, record) => (
				<>
					<span>{record?.bulk_order_date}</span>
				</>
			))
		},
		{
			title: "Order Details",
			dataIndex: "info",
			key: "info",
			render: ((text, record) => (
				<>
				 <Button className="craete-order-button" onClick={() => { setDetailModal(true); setPatientDetails(record) }}>View Details</Button>
				</>
			))
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: ((text, record) => (
				<>
					<span>{record?.patients[0]?.status === "success" ?  <Tag color="green">Successfull</Tag> : <Tag color="red">Denied</Tag>}</span>
				</>
			))
		},
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
			render: ((text, record) => (
				<>
					<span>{record?.patients[0]?.denial_reason ? record?.patients[0]?.denial_reason : "N/A"}</span>
				</>
			))
		},
	];

	const BulkOrderColumn = [
		{
			title: "Patient Name",
			dataIndex: "patient_name",
			key: "patient_name",
			render: ((text, record) => (
				<>
					<span>{record?.patient_name}</span>
				</>
			))
		},
		{
			title: "Date Last Ordered",
			dataIndex: "dateLastOrdered",
			key: "dateLastOrdered",
			render: ((text, record) => (
				<>
					<span>{record?.last_date_ordered ? moment(record.last_date_ordered).format('MM/DD/YY'): "N/A"}</span>
					
				</>
			))

		},
		{
			title: "Order Quantity",
			dataIndex: "orderQuantity",
			key: "orderQuantity",
			render: ((text, record) => (
				<>
					<span>{record?.quantity ? record?.quantity : "N/A"}</span>
				</>
			))

		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			render: ((text, record) => (
				<>
					<span>${record?.amount}</span>
				</>
			))

		},
		{
			title: "Patient Address",
			dataIndex: "address",
			key: "address",
			render: ((text, record) => (
				<>
					<span>{record?.address && record?.address !=="" ? record?.address :"N/A"}</span>
				</>
			))

		},

	];


	const onPageSizeChange = (current, size) => {
		setOrderPerPage(size);
		setOrderPage(1);
	};

	return (
		<>
			<div className="wrapper">
				<div className="container-fluid">
					<div className="row mb-5">
						<div className="col-md-12">
							<div className="titleBar titleRow">
								<h4>Bulk Order List</h4>
								<Input
									placeholder="Search Order.."
									onChange={handleOrderSearch}
									className="homesearchInput titleRowInput"
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="tableWrapper">
								<Table
									className="registered-user-table"
									columns={Patientdetailscolumn}
									dataSource={orderData}
									pagination={{
										current: orderPage,
										pageSize: orderPerPage,
										total: orderTotal,
										onChange: (page) => setOrderPage(page),
										onShowSizeChange: onPageSizeChange,
										showSizeChanger: true,
										showQuickJumper: true,
										pageSizeOptions: pageSizeOptions,
									}}
									loading={isLoadinggetOrder}
									showSorterTooltip={false}
									scroll={{ x: 'auto' }}
									bordered
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				title="Order Details"
				centered
				open={detailModal}
				onOk={() => { setDetailModal(false); setPatientDetails([]) }}
				onCancel={() => { setDetailModal(false); setPatientDetails([]) }}
				className="bulk-order-modal"
				footer={false}
				width={900}
			>
				<div className="tableWrapper registered-user-table mt-4 mb-4">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Name:</label> {patientDetails?.data?.order?.rxs[0]?.drugName ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Strength:</label> {patientDetails?.data?.order?.rxs[0]?.drugStrength ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Form:</label> {patientDetails?.data?.order?.rxs[0]?.drugForm ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Quantity Unit:</label> {patientDetails?.data?.order?.rxs[0]?.quantityUnits ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Total Amount:</label> ${patientDetails?.total_amount ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Clinical Statement:</label> {patientDetails?.data?.order?.rxs[0]?.clinicalDifferenceStatement ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Drug Directions:</label> {patientDetails?.data?.order?.rxs[0]?.directions ?? "N/A"}</div>
                        </div>
                        <div className="col-md-6">
                            <div className='formGroup'><label>Shipping Address:</label> {patientDetails?.data?.order?.new_shipping_address ? patientDetails?.data?.order?.new_shipping_address: patientDetails?.shipping?.addressLine1 ?? "N/A"}</div>
                        </div>
                    </div>
                </div>
				<div className="tableWrapper registered-user-table">
					<Table
						columns={BulkOrderColumn}
						dataSource={patientDetails?.patients}
						pagination={true}
						bordered
						className="mb-4 customOrderTable productTable"
						loading={false}
						scroll={{ x: 'auto' }}
					/>
				</div>
			</Modal>
		</>
	)
}
