import React, { useState, useEffect } from "react";
import { Table, Input, Select } from "antd";
import { useGetRecentPatientsMutation } from '../../redux/slices/patientSlice';
import { MdEdit } from "react-icons/md";
import {useNavigate} from "react-router-dom";
import debounce from '../../HelperFunctions';

const PatientList = () => {
   const { Option } = Select;
   const navigate = useNavigate()
   const [getRecentPatients, { isLoading }] = useGetRecentPatientsMutation();
   const [filteredPatientData, setFilteredPatientData] = useState([]);
   const [patientTotal, setPatientTotal] = useState(0);
   const [filteredPatientTotal, setFilteresPatientTotal] = useState(0);
   const [patientPerPage, setPatientPerPage] = useState(10);
   const [patientPage, setPatientPage] = useState(1);
   const [search, setSearch] = useState('');
   const [dayFilter, setDayFilter] = useState(null);

   const getPatientData = (value) => {
      getRecentPatients(value).then((response) => {
         setFilteredPatientData(response?.data?.data)
         setPatientTotal(response?.data?.total)
         setFilteresPatientTotal(response?.data?.total)
      }).catch((err) => {
         console.error("err", err);
      });
   }

   useEffect(() => {
      let query = `?per_page=${patientPerPage}&page=${patientPage}`;

      if (dayFilter) {
         query += `&days=${dayFilter}`
      }

      if (search !== '') {
         query += `&search=${search}`
      }
      getPatientData(query)
   }, [patientPerPage, patientPage, dayFilter])

   const onPageSizeChange = (current, size) => {
      setPatientPerPage(size);
      setPatientPage(1);
   };

   const generatePageSizeOptions = (total) => {
      const options = [];
      const maxPageSize = Math.min(total, 100);

      for (let i = 5; i <= maxPageSize; i += 5) {
         options.push(i);
      }
      if (total > 5 && !options.includes(10)) {
         options.push(10);
      }

      if (total > 10 && !options.includes(15)) {
         options.push(15);
      }
      return options.sort((a, b) => a - b);
   };

   const pageSizeOptions = generatePageSizeOptions(patientTotal);

   const tableColumns = [
      {
         title: "S.No",
         key: "serial_number",
         render: (text, record, index) =>
            <span>{(patientPage - 1) * patientPerPage + index + 1}</span>,
      },
      {
         title: "Patient Name",
         dataIndex: "name",
         key: "name",
         // sorter: (a, b) => a.name.length - b.name.length,
         render: (text, record) => (
            <>
               <span>{`${record?.first_name} ${record?.last_name}`}</span>
            </>
         )
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         // sorter: (a, b) => a.email.length - b.email.length,
         render: (text, record) => (
            <>
               {record?.email ? (<span>{record?.email}</span>) : (<span className="text-center">"N/A"</span>)}
            </>
         )
      },
      {
         title: "Phone",
         dataIndex: "phone",
         key: "phone",
         // sorter: (a, b) => a.phone.length - b.phone.length,
         render: (text, record) => (
            <>
               {record?.phone ? (<span>{record?.phone}</span>) : (<span className="text-center">N/A</span>)}
            </>
         )
      },
      {
         title: "Address",
         dataIndex: "address",
         key: "address",
         // sorter: (a, b) => a.address.length - b.address.length,
         render: (text, record) => (
            <>
               <span>{record?.address !== "" ? record?.address : "N/A"}</span>
            </>
         )
      },
      {
         title: "City",
         dataIndex: "city",
         key: "city",
         // sorter: (a, b) => a.city.length - b.city.length,
         render: (text, record) => (
            <>
               <span>{record?.city !== "" ? record?.city : "N/A"}</span>
            </>
         )
      },
      {
         title: "State",
         dataIndex: "state",
         key: "state",
         // sorter: (a, b) => a.state.length - b.state.length,
         render: (text, record) => (
            <>
               <span>{record?.state !== "" ? record?.state : "N/A"}</span>
            </>
         )
      },
      {
         title: "Zip",
         dataIndex: "zip",
         key: "zip",
         // sorter: (a, b) => a.zip.length - b.zip.length,
         render: (text, record) => (
            <>
               <span>{record?.zip !== "" ? record?.zip : "N/A"}</span>
            </>
         )
      },
      {
         title: "Action",
         dataIndex: "action",
         key: "action",
         render: (text, record) => (
            <>
                <MdEdit style={{ cursor: "pointer", color: '#b4a099' }}  onClick={()=> navigate("/edit-patient",{
                  state:{
                     patientId:record?.id
                  }
                })}/>
            </>
         )
      },


   ];

   const handlePatientSearch = async (event) => {
      setSearch(event?.target?.value);
      let query = `?per_page=${patientPerPage}&page=${patientPage}`;
      if (dayFilter) {
         query += `&days=${dayFilter}`
      }
      if (event?.target?.value !== '') {
          query += `&search=${event?.target?.value}`
      }
      let response = await debounce(getPatientData, query)
      setFilteredPatientData(response?.data?.data)
      setPatientTotal(response?.data?.total)
  };


   const handledayChange = (day) => {
      setDayFilter(day)
   }


   return (
      <div className="wrapper">
         <div className="container-fluid">
            <div className="row mb-5">
               <div className="col-md-12">
                  <div className="titleBar titleRow">
                     <h4>Patient List</h4>
                     <div>
                        <Select
                           placeholder="Select Recent Patient Duration"
                           className="formSelect me-3"
                           onChange={(value) => {
                              handledayChange(value);
                           }}
                        >
                           <Option value="7">7 days</Option>
                           <Option value="30">30 days</Option>
                           <Option value="45">45 days</Option>
                           <Option value="60">60 days</Option>
                           <Option value="90">90 days</Option>
                           <Option value="120">120 days</Option>
                        </Select>
                        <Input
                           style={{ height: "50px" }}
                           placeholder="Search here.."
                           onChange={handlePatientSearch}
                           className="homesearchInput titleRowInput"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-md-12">
                  <div className="tableWrapper">
                     <Table
                        className="registered-user-table"
                        columns={tableColumns}
                        dataSource={filteredPatientData}
                        pagination={{
                           current: patientPage,
                           pageSize: patientPerPage,
                           total: filteredPatientTotal,
                           onChange: (page) => setPatientPage(page),
                           onShowSizeChange: onPageSizeChange,
                           showSizeChanger: true,
                           showQuickJumper: true,
                           pageSizeOptions: pageSizeOptions,
                        }}
                        loading={isLoading}
                        showSorterTooltip={false}
                        scroll={{ x: 'auto' }}
                        bordered
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default PatientList;