import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../../redux/slices/userSlice";
import {MdRefresh } from "react-icons/md";
import Swal from "sweetalert2";
const ForgotPassword = () => {
	let navigate = useNavigate();
	const [form] = Form.useForm();
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [forgotPassword,{isLoading: isResetPasswordLoading}] = useForgotPasswordMutation();
	const onFinish = async (value) => {
		const response = await forgotPassword(value);
		if (response.error) {
			Swal.fire({
				title: `Failed`,
				text: response.error.data.message,
				icon: "error",
				confirmButtonText: "OK",
			});
			return;
		}
		const { success, message } = response.data;
		if (success == true) {
			Swal.fire({
				title: message,
				icon: "success",
			}).then(()=>{
				navigate("/login");
			});
		}
		
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const allFieldsFilled = !!values.email;
		setIsButtonDisabled(!allFieldsFilled);
	};

	return (
		<div className="authWrapper">
			<div className="innerWrapper">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-5 col-md-7">
							<h4 className="cardTitle">Lifeline Inventory</h4>
							<div className="card authCard">
								<div className="card-body">
									<h5 className="title">Password Reset</h5>
									<p className="subText">
										Enter your email address and we'll send you instructions on
										how to reset your password.
									</p>
									<div className="formWrapper">
										<Form
											form={form}
											name="loginForm"
											onFinish={onFinish}
											onFinishFailed={onFinishFailed}
											onValuesChange={handleFormChange}
											autoComplete="off"
											layout="vertical"
										>
											<Form.Item
												label="Email"
												name="email"
												rules={[
													{
														required: true,
														message: "Please input your email!",
													},
												]}
											>
												<Input
													className="formControl"
													placeholder="Enter your email"
												/>
											</Form.Item>

											<Form.Item className="text-center mt-5 w-100">
												<Button
													type="primary"
													htmlType="submit"
													className="authBtn w-100"
													icon={<MdRefresh/>}
													loading={isResetPasswordLoading}
													disabled={isButtonDisabled || isResetPasswordLoading}
												>
													Submit
												</Button>
											</Form.Item>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
