import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { MdHomeFilled, MdArrowBack } from "react-icons/md";
import { Button, Form, Input, message, Upload, Select, Skeleton } from "antd";
import { MdRefresh } from "react-icons/md";
import { Country, State, City } from "country-state-city";
import { useGetPatientByIdMutation,useUpdatePatientMutation } from "../../redux/slices/patientSlice";
import Swal from "sweetalert2";

export default function EditPatient() {
    const { Option } = Select;
    const { state } = useLocation()
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [getPatientById, { isLoading }] = useGetPatientByIdMutation();
    const [updatePatient, { isLoading: UpdatePatientLoading }] = useUpdatePatientMutation();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [patientDetails, setPatientDetails] = useState(null)

    const getPatientByID = (id) => {
        getPatientById(id).then((response) => {
            setPatientDetails(response?.data?.patient)
            form.setFieldsValue({
                first_name: response?.data?.patient?.first_name,
                last_name: response?.data?.patient?.last_name,
                email: response?.data?.patient?.email,
                phone: response?.data?.patient?.phone,
                referred_by: response?.data?.patient?.referred_by,
                general_tag: response?.data?.patient?.general_tag,
                zip: response?.data?.patient?.zip,
                address: response?.data?.patient?.address,
            });
        }).catch((error) => {
            console.error({ error })
        })
    }

    useEffect(() => {
        getPatientByID(state?.patientId)
    }, [state?.patientId])

    const handleCountryChange = (value) => {
        const selected = countries.find((country) => country.name === value);
    
        const structuredValue = {
            isoCode: selected?.isoCode,
            name: selected?.name,
        };
    
        setSelectedCountry(structuredValue);
    
        setStates(State.getStatesOfCountry(selected?.isoCode));
        setCities([]);
        form.setFieldsValue({ state: null, city: null });
    };
    

    // Function to handle state change
    const handleStateChange = (value) => {
        const selected = states.find((state) => state.name === value);
        setSelectedState(selected);
        setCities(City.getCitiesOfState(selectedCountry?.isoCode, selected?.isoCode));
        form.setFieldsValue({ city: null });
    };

    // Function to handle city change
    const handleCityChange = (value) => {
        setSelectedCity(value);
    };

    useEffect(() => {
        if (patientDetails) {
            const { city, state, zip, address } = patientDetails;
    
            let foundState = null;
            let foundCountry = null;
    
            if (state) {
                foundState = State.getAllStates().find((s) => s.name === state);
                foundCountry = Country.getAllCountries().find(
                    (c) => c.isoCode === foundState?.countryCode
                );
            } else if (city) {
                const foundCity = City.getAllCities().find((c) => c.name === city);
                if (foundCity) {
                    foundState = State.getAllStates().find(
                        (s) => s?.isoCode === foundCity?.stateCode &&  s?.countryCode === foundCity?.countryCode
                    );
                    foundCountry = Country.getAllCountries().find(
                        (c) => c.isoCode === foundCity.countryCode
                    );
                }
            }
    
            // Fetch cities for the found state
            const foundCities = foundState
                ? City.getCitiesOfState(foundCountry?.isoCode, foundState?.isoCode)
                : [];
    
            setSelectedCountry(foundCountry || null);
            setSelectedState(foundState || null);
            setCities(foundCities || []);
    
            form.setFieldsValue({
                address,
                city,
                state: foundState?.name  || null,
                country: foundCountry?.name || null,
                zip,
            });
        }
    }, [patientDetails, form]);
    


    const onFinish = (values) => {
         let formdata = {
            "first_name": values?.first_name,
            "last_name": values?.last_name,
            "email": values?.email,
            "phone": values?.phone,
            "address": values?.address,
            "city": values?.city,
            "state": values?.state,
            "zip": values?.zip,
            "referred_by": values?.referred_by,
            "general_tag": values?.general_tag
        }
         updatePatient({id:state?.patientId,value:formdata}).then((response)=>{

            if (response.error) {
                Swal.fire({
                    title: response?.error?.data?.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                return;
            }else{
                Swal.fire({
                    title: response?.data?.message,
                    icon: "success",
                    confirmButtonText: "OK",
                }).then(()=>{
                    navigate("/patient-list")
                });
            }

         }).catch((error)=>{
            Swal.fire({
                title: "Failed",
                text: "Failed to update patient details.",
                icon: "error",
                confirmButtonText: "OK",
            });
         })

    }

    const onFinishFailed = (error) => {
        console.error({ error })
    }


    const handleGoBack = () => {
        navigate(-1);
    };
    const handleGoHome = () => {
        navigate("/");
    };



    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Edit Patient</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleGoBack()}
                                >
                                    <MdArrowBack /> Back
                                </button>
                                <button
                                    type="button"
                                    className="btn homeBtn"
                                    onClick={() => handleGoHome()}
                                >
                                    <MdHomeFilled /> Go to Home
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {isLoading ? (
                            <div className="text-center my-5">
                                <Skeleton />;
                            </div>
                        ) : (
                            <div className="formWrapper">
                                <Form
                                    form={form}
                                    name="accountForm1"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="First Name"
                                                name="first_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter first name!",
                                                    },
                                                ]}
                                            >
                                                <Input disabled={UpdatePatientLoading} className="formControl" placeholder="Enter First Name" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Last Name"
                                                name="last_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter last name!",
                                                    },
                                                ]}
                                            >
                                                <Input disabled={UpdatePatientLoading} className="formControl" placeholder="Enter Last Name" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Email"
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter first name!",
                                                        type: "email"
                                                    },
                                                ]}
                                            >
                                                <Input disabled={UpdatePatientLoading} className="formControl" placeholder="Enter Email" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Phone"
                                                name="phone"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter phone number.",
                                                    },
                                                    {
                                                        validator: (_, value) =>
                                                            value && value.length === 10
                                                                ? Promise.resolve()
                                                                : Promise.reject(
                                                                    new Error(
                                                                        "Phone number must be exactly 10 digits."
                                                                    )
                                                                ),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={UpdatePatientLoading} className="formControl" placeholder="Enter Phone Number" maxLength={10}
										onKeyPress={(e) => {
											if (!/[0-9]/.test(e.key)) {
												e.preventDefault();
											}
										}}
										onPaste={(e) => {
											const paste = (
												e.clipboardData || window.clipboardData
											).getData("text");
											if (!/^[0-9]+$/.test(paste)) {
												e.preventDefault();
											}
										}} />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Referred By"
                                                name="referred_by"

                                            >
                                                <Input disabled={UpdatePatientLoading} className="formControl" placeholder="Enter Referred By" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="General Tag"
                                                name="general_tag"

                                            >
                                                <Input disabled={UpdatePatientLoading} className="formControl" placeholder="Enter General Tag" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Select Country"
                                                name="country"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select country.",
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select Country"
                                                    className="formSelect"
                                                    onChange={(e)=>handleCountryChange(e)}
                                                    onClick={()=> setCountries(Country.getAllCountries())}
                                                    disabled={UpdatePatientLoading}
                                                >
                                                    {countries.map((country) => (
                                                        <Option key={country.isoCode} value={country.name}>
                                                            {country.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Select State"
                                                name="state"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select state.",
                                                    }
                                                ]}
                                            >
                                                <Select
                                                   className="formSelect"
                                                    showSearch
                                                    placeholder="Select State"
                                                    onChange={handleStateChange}
                                                    disabled={!selectedCountry || UpdatePatientLoading}
                                                    onClick={()=> setStates(State?.getStatesOfCountry(selectedCountry?.isoCode))}
                                                >
                                                    {states.map((state) => (
                                                        <Option key={state.isoCode} value={state.name}>
                                                            {state.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Select City"
                                                name="city"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select city.",
                                                    }
                                                ]}

                                            >
                                                <Select
                                                   className="formSelect"
                                                    showSearch
                                                    placeholder="Select City"
                                                    onChange={handleCityChange}
                                                    disabled={!selectedState || UpdatePatientLoading}
                                                    onClick={()=> setCities(City.getCitiesOfState(
                                                        selectedCountry?.isoCode,
                                                        selectedState?.isoCode
                                                    ))}
                                                    
                                                >
                                                    {cities.map((city) => (
                                                        <Option key={city.isoCode} value={city.name}>
                                                            {city.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Zip Code"
                                                name="zip"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter zip code!",
                                                    },
                                                ]}

                                            >
                                                <Input disabled={UpdatePatientLoading} className="formControl" placeholder="Enter Zip Code" maxLength={10}
								onKeyPress={(e) => {
									if (!/[0-9]/.test(e.key)) {
										e.preventDefault();
									}
								}}
								onPaste={(e) => {
									const paste = (
										e.clipboardData || window.clipboardData
									).getData("text");
									if (!/^[0-9]+$/.test(paste)) {
										e.preventDefault();
									}
								}} />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-12">
                                            <Form.Item
                                                label="Address"
                                                name="address"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter address!",
                                                    },
                                                ]}

                                            >
                                                <TextArea disabled={UpdatePatientLoading} rows={6} placeholder="Enter Address" />
                                            </Form.Item>
                                        </div>


                                        <div className="col-md-12 text-center my-5">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="submitBtn w-auto px-4"
                                                 disabled={UpdatePatientLoading}
                                                icon={<MdRefresh style={{ fontSize: "20px", marginRight: "0.5rem" }} />}
                                             loading={UpdatePatientLoading}
                                            >
                                                Update Patient
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}
