import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api_Url } from "../../Helper";
import Logout from "../../LogoutHelper";
const commonHeaders = {
  Accept: "application/json",
};

const customBaseQuery = fetchBaseQuery({
  baseUrl: Api_Url,
  prepareHeaders: (headers,{ getState }) => {
    const token = getState()?.persistedReducer?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await customBaseQuery(args, api, extraOptions);
  if (result?.error && result?.error?.status === 401) {
    Logout();
  }
  return result;
};


export const patientSlice = createApi({
  reducerPath: "patientSlice",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPatient: builder.mutation({
      query: (value) => {
        return {
          url: `/patients${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getFilteredpatient: builder.mutation({
      query: (value) => {
        return {
          url: `/patients/filter${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getAppointment: builder.mutation({
      query: (value) => {
        return {
          url: `/appointments-with-patient-name${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getPatientappointment: builder.mutation({
      query: (patientId) => {
        return {
          url: `/patients/${patientId}/appointments`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    addAudience: builder.mutation({
      query: (audiencedata) => {
        return {
          url: "/audiences",
          method: "POST",
          body: audiencedata,
          headers: commonHeaders,
        };
      },
    }),
    importAudience: builder.mutation({
      query: (importeddata) => {
        return {
          url: "/import-contacts",
          method: "POST",
          body: importeddata,
          headers: commonHeaders,
        };
      },
    }),
    getAudience: builder.mutation({
      query: () => {
        return {
          url: "/audiences",
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getLists: builder.mutation({
      query: () => {
        return {
          url: "/contact-lists",
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getAudiencePatient: builder.mutation({
      query: (id) => {
        return {
          url: `/audiences/${id}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getPatientStatistics: builder.mutation({
      query: () => {
        return {
          url: `/patient-statistics`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getPatientsWithAppointments: builder.mutation({
      query: (value) => {
        return {
          url: `/patients/appointments${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),
    getRecentPatients: builder.mutation({
      query: (value) => {
        return {
          url: `/patients/recent${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),

    getPatientById: builder.mutation({
      query: (value) => {
        return {
          url: `/patients/${value}`,
          method: "GET",
          headers: commonHeaders,
        };
      },
    }),

    updatePatient: builder.mutation({
      query: ({ id, value }) => {
        return {
          url: `/patients/${id}`,
          method: "PUT",
          headers: commonHeaders,
          body: value,
        };
      },
    }),

    updateAudenceStatus: builder.mutation({
      query: ({ id, value }) => {
        return {
          url: `/audiences/${id}/status`,
          method: "PATCH",
          headers: commonHeaders,
          body: value,
        };
      },
    }),

    deleteAudienceList: builder.mutation({
      query: (id) => {
        return {
          url: `/audiences/${id}`,
          method: "DELETE",
          headers: commonHeaders,
        };
      },
    }),
  }),
});
export const { useGetPatientMutation, useGetFilteredpatientMutation, useGetAppointmentMutation, useGetPatientappointmentMutation, useAddAudienceMutation, useImportAudienceMutation, useGetAudienceMutation, useGetListsMutation, useGetAudiencePatientMutation, useGetPatientStatisticsMutation, useGetPatientsWithAppointmentsMutation, useGetRecentPatientsMutation, useGetPatientByIdMutation, useUpdatePatientMutation, useUpdateAudenceStatusMutation, useDeleteAudienceListMutation } = patientSlice;