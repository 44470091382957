import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Table, Descriptions } from "antd";
import { MdArrowBack } from "react-icons/md";
import { useGetPatientappointmentMutation } from '../../redux/slices/patientSlice';
import moment from 'moment';

const Patientappointment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [getPatientappointment, { isLoading }] = useGetPatientappointmentMutation();
    const [appointmentData, setappointmentData] = useState([]);
    const [userData, setUserData] = useState({});
    const { patientId } = location.state || {};
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        getPatientappointment(patientId)
            .then((res) => {
                const {  appointments } = res.data;
                setUserData(res.data);

                const formattedData = appointments.map((item) => ({
                    key: item.id,
                    appointment_id: item.appointment_id,
                    service_category: item.service_category,
                    event_type: item.event_type,
                    booked: item.online_versus_inhouse,
                    booktime: moment(item.created_at).format('MM/DD/YY hh:mm:ss A'),
                    lastseen: moment(item.updated_at).format('MM/DD/YY'),
                    status: item.booking_status
                }));
                setappointmentData(formattedData);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }, []);

    const tableColumns = [
        {
            title: "S.No.",
            dataIndex: "index",
            key: "index",
            render: (_, record, index) => 
                (pagination.current - 1) * pagination.pageSize + index + 1,
        },
        {
            title: "Category",
            dataIndex: "service_category",
            key: "service_category",
            // sorter: (a, b) => a.service_category.length - b.service_category.length,
        },
        {
            title: "Event Type",
            dataIndex: "event_type",
            key: "event_type",
            // sorter: (a, b) => a.event_type.length - b.event_type.length,
        },
        {
            title: "Booking",
            dataIndex: "booked",
            key: "booked",
            // sorter: (a, b) => a.booked.length - b.booked.length,
        },
        {
            title: "Booking Time",
            dataIndex: "booktime",
            key: "booktime",
            // sorter: (a, b) => a.booktime.length - b.booktime.length,
        },
        {
            title: "Last Updated",
            dataIndex: "lastseen",
            key: "lastseen",
            // sorter: (a, b) => a.lastseen.length - b.lastseen.length,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
    ];

    const profileItems = [
        { key: "1", label: "First Name", children: userData.first_name || "N/A" },
        { key: "2", label: "Last Name", children: userData.last_name || "N/A" },
        { key: "3", label: "Email", children: userData.email || "N/A" },
        { key: "4", label: "Phone", children: userData.phone || "N/A" },
        { key: "5", label: "Address", children: userData.address || "N/A" },
        { key: "6", label: "State", children: userData.state || "N/A" },
        { key: "7", label: "City", children: userData.city || "N/A" },
        { key: "8", label: "Zip", children: userData.zip || "N/A" },
    ];

    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Patient Appointments List</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleGoBack()}
                                >
                                    <MdArrowBack /> Back
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Descriptions title="Patient Info" layout="vertical" items={profileItems} />
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={appointmentData}
                                pagination={{
                                    current: pagination.current,
                                    pageSize: pagination.pageSize,
                                    total: appointmentData.length,
                                }}
                                loading={isLoading}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Patientappointment;