import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, } from "react-router-dom";
import {  Table } from 'antd';
import { MdArrowBack } from "react-icons/md";
import { useGetAudiencePatientMutation } from '../../redux/slices/patientSlice';

const Audienceuser=()=> {
    const navigate = useNavigate();
    const location = useLocation();
    const [audienceData, setaudienceData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); 
    const [getAudiencePatient, { isLoading }] = useGetAudiencePatientMutation();
    const { AudienceId } = location.state || {};
    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        getAudiencePatient(AudienceId)
            .then((res) => {
                const Pareseddata=JSON.parse(res.data.audience);
                const formattedData =Pareseddata.map((item) => ({
                    key: item.id,
                    first_name:item.first_name,
                    email:item.email,
                }));
                setaudienceData(formattedData);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }, []);

    const tableColumns = [
        {
            title: "S.No",
            key: "serial_number",
            render: (text, record, index) => {
                return <span>{(currentPage - 1) * pageSize + index + 1}</span>;
            },
        },
        {
            title: "Name",
            dataIndex: "first_name",
            key: "first_name",
            // sorter: (a, b) => a.first_name.length - b.first_name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        
    ];


    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Audiences List</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={() => handleGoBack()}
                                >
                                    <MdArrowBack /> Back
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={audienceData}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: audienceData.length,
                                    onChange: (page, size) => {
                                        setCurrentPage(page);
                                        setPageSize(size);
                                    },
                                    showSizeChanger: true,
                                    showQuickJumper: false,
                                    pageSizeOptions: ["5", "10", "15", "20"],
                                }}
                                loading={isLoading}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Audienceuser;