import React, { useState, useEffect } from "react";
import { Table, Input, Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { MdAddBox, MdDelete, MdEdit } from "react-icons/md";
import { useGetProductMutation, useDeleteProductMutation, useGetCategoriesMutation } from "../../redux/slices/productSlice";
import debounce from '../../HelperFunctions';
import Swal from 'sweetalert2'
const Homepage = () => {
    const navigate = useNavigate();
    const [getProduct, { isLoading }] = useGetProductMutation();
    const [getCategories, { isLoading: isCategoriesLoading }] = useGetCategoriesMutation();
    const [deleteProduct] = useDeleteProductMutation();
    const [filteredData, setFilteredData] = useState([]);
    const [productPerPage, setProductPerPage] = useState(10);
    const [productPage, setProductPage] = useState(1)
    const [productTotal, setProductTotal] = useState(0)
    const [categories, setCategories] = useState([])
    const [search, setSearch] = useState(null)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const getProductData = async (query) => {
        try {
            const res = await getProduct(query);
            setProductTotal(res?.data?.total)
            setFilteredData(res?.data?.data);
        } catch (error) {
            console.error("error", error);
        }
    }
    useEffect(() => {
        let query = `?per_page=${productPerPage}&page=${productPage}`
        if (selectedCategories?.length > 0) {
            query += `&category_id=${JSON.stringify(selectedCategories)}`
        }

        if (search) {
            query += `&search=${search}`
        }
        getProductData(query);
    }, [productPerPage, productPage, selectedCategories]);


    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(productTotal);

    const onPageSizeChange = (current, size) => {
        setProductPerPage(size);
        setProductPage(1);
    };



    const tableColumns = [
        {
            title: "S.No",
            key: "serial_number",
            render: (text, record, index) =>
                <span>{(productPage - 1) * productPerPage + index + 1}</span>,
        },
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            render: (text, record) => (
                <div className="productBox" style={{ display: "flex", alignItems: "center" }}>
                    <div className="imgBox" style={{ marginRight: "20px" }}>
                        {record?.image ? (
                            <img
                                src={record?.image}
                                alt={record?.item_name}
                                className="img-fluid"
                                style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px" }}
                            />
                        ) : (
                            <p className="prouct-image-text">Product Image</p>
                        )}

                    </div>
                    <span style={{ textTransform: 'capitalize', fontWeight: '600' }}>{record.item_name}</span>
                </div>
            ),
        },
        {
            title: "Company name",
            dataIndex: "company",
            key: "company",
            // sorter: (a, b) => a.company.length - b.company.length,
            render: (text, record) => (
                <>
                    <span>{record?.company_name ? record?.company_name : "N/A"}</span>
                </>
            ),
        },
        {
            title: "Vendor name",
            dataIndex: "vendor",
            key: "vendor",
            // sorter: (a, b) => a.vendor.length - b.vendor.length,
            render: (text, record) => (
                <>
                    <span>{record?.vendor_name ? record?.vendor_name : "N/A"}</span>
                </>
            ),
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
            // sorter: (a, b) => a.vendor.length - b.vendor.length,
            render: (text, record) => (
                <span>{record?.created_by ?? "N/A"}</span>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            // sorter: (a, b) => a.category.length - b.category.length,
            render: (text, record) => (
                <span>{record?.category?.name ?? "N/A"}</span>
            ),
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            // sorter: (a, b) => a.price.length - b.price.length,
            render: (text, record) => (
                <span>{record?.price ?? "N/A"}</span>
            ),
        },
        {
            title: "In Stock",
            dataIndex: "stock",
            key: "stock",
            // sorter: (a, b) => a.stock - b.stock,
            render: (text, record) => (
                <span>{record?.quantity ? record?.quantity : "N/A"}</span>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="actionButton">
                    <button type="button" className="btn primaryBtn" onClick={(e) => {
                        e.stopPropagation();
                        navigate("/edit-product", {
                            state: { productId: record.id },
                        });
                    }}
                    >
                        <MdEdit style={{ cursor: "pointer", color: '#b4a099' }} />
                    </button>
                    <button type="button" className="btn primaryBtn" onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(record.id);
                    }}
                    >
                        <MdDelete
                            style={{ cursor: "pointer", color: '#ff0000' }}
                        />
                    </button>
                </div>

            ),
        },
    ];
    const handleDelete = async (id) => {
        try {
            Swal.fire({
                title: "Are you sure you want to delete this product?",
                showDenyButton: true,
                icon: "warning",
                confirmButtonText: "Yes",
                denyButtonText: "No",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await deleteProduct(id); // Await the deleteProduct function
                    const { product, message } = response.data;

                    if (product) {
                        Swal.fire({
                            title: message,
                            icon: "success",
                        });
                        let query = `?per_page=${10}&page=${1}`
                        if (selectedCategories?.length > 0) {
                            query += `&category_id=${JSON.stringify(selectedCategories)}`
                        }

                        if (search) {
                            query += `&search=${search}`
                        }
                        getProductData(query);
                    } else if (response.error) {
                        Swal.fire({
                            title: response.error.data.message,
                            icon: "error",
                            confirmButtonText: 'OK',
                        });
                        return;
                    }
                } else if (result.isDenied) {
                    Swal.fire({
                        title: "Your product is safe!",
                        icon: "info",
                        confirmButtonText: "OK",
                    });
                }
            });


        } catch (error) {
            console.error("Error deleting product:", error);
            Swal.fire({
                title: "Error deleting product",
                icon: "error",
                confirmButtonText: 'OK',
            });
        }
    };

    const getCategoriesList = () => {
        getCategories().then((response) => {
            setCategories(response?.data?.categories)
        }).catch((error) => {
            console.error({ error })
        })
    }

    const handleAddProduct = () => {
        navigate("/add-product");
    };

    const handleRowClick = (record) => {
        navigate("/product-details", {
            state: { productId: record.id },
        });
    };

    const handleCategoryChange = (value) => {
        setSelectedCategories(value)
    }

    const handleSearchProduct = async (event) => {
        setSearch(event?.target?.value);
        let query = `?per_page=${productPerPage}&page=${productPage}`;
        if (event?.target?.value !== '') {
            query += `&search=${event?.target?.value}`
        }
        if (selectedCategories?.length > 0) {
            query += `&category_id=${JSON.stringify(selectedCategories)}`
        }
        let res = await debounce(getProduct, query)
        setFilteredData(res?.data?.data)
        setProductTotal(res?.data?.total)
    };

    // Handle Select All
    const handleSelectAll = () => {
        const allIds = categories.map((option) => option.id);
        setSelectedCategories(allIds);
    };

    // Handle Unselect All
    const handleUnselectAll = () => {
        setSelectedCategories([]);
    };

    return (
        <div className="wrapper productCatalogWrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Products Catalog</h4>
                            <div className="rightWrapper">
                                <Input
                                    placeholder="Search here"
                                    onChange={handleSearchProduct}
                                    className="homesearchInput titleRowInput"
                                />
                                {/* <Select
                                    placeholder="Select Parent Category"
                                    className="formSelect category-select me-3"
                                    onClick={() => categories?.length === 0 ? getCategoriesList() : <></>}
                                    onChange={(e) => {
                                        handleCategoryChange(e)
                                    }}
                                    notFoundContent={isCategoriesLoading ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Spin />
                                        </div>
                                    ) : null}
                                    options={categories?.map((option) => ({
                                        value: option.id,
                                        label: option.name,
                                    }))}
                                /> */}
                                <Select
                                    mode="multiple"
                                    value={selectedCategories}
                                    placeholder="Select Parent Category"
                                    className="formSelect category-select me-3"
                                    onClick={() => {
                                        if (categories?.length === 0) getCategoriesList();
                                    }}
                                    onChange={(e) => handleCategoryChange(e)}
                                    notFoundContent={
                                        isCategoriesLoading ? (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Spin />
                                            </div>
                                        ) : "No categories found"
                                    }
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().includes(input.toLowerCase())
                                    }
                                    dropdownRender={(menu) => (
                                        <>
                                            <div style={{ padding: "8px" }}>
                                                {selectedCategories?.length === categories?.length ? (
                                                    <span onClick={handleUnselectAll} style={{ cursor: "pointer" }}>
                                                        Unselect All
                                                    </span>
                                                ) : (
                                                    <span onClick={handleSelectAll} style={{ cursor: "pointer" }}>
                                                        Select All
                                                    </span>
                                                )}
                                            </div>
                                            {menu}
                                        </>
                                    )}
                                >
                                    {categories?.map((option) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>

                                <div className="buttonWrap">
                                    <button
                                        type="button"
                                        className="btn backBtn"
                                        onClick={() => handleAddProduct()}
                                    >
                                        <MdAddBox /> Add Product
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredData}
                                pagination={{
                                    current: productPage,
                                    pageSize: productPerPage,
                                    total: productTotal,
                                    onChange: (page) => setProductPage(page),
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: pageSizeOptions,
                                }}
                                loading={isLoading}
                                onRow={(record) => ({
                                    onClick: () => handleRowClick(record),
                                })}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
