import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Select, Input, Table, Button, Radio, Spin,Tooltip  } from 'antd';
import { useAddAudienceMutation, useGetAudienceMutation, useGetFilteredpatientMutation, useGetListsMutation, useImportAudienceMutation, useUpdateAudenceStatusMutation, useDeleteAudienceListMutation } from '../../redux/slices/patientSlice';
import Swal from "sweetalert2";
import {MdDelete} from "react-icons/md";

const Audience = () => {
    const { Option } = Select;
    const [audienceData, setAudienceData] = useState([]);
    const [type_of_audience, setAudienceType] = useState('One Time');
    const [appointment_type, setAppointmentType] = useState([]);
    const [last_visit_days, setLastVisit] = useState(null);
    // const [status, setStatus] = useState(null);
    const [campaign_type, setCampaignType] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [list_id, setListId] = useState(null);
    const [name, setName] = useState('');
    const [ListIds, setListIds] = useState([]);
    const [getLists] = useGetListsMutation();
    const [importAudience, {isLoading: ConnectionLoading}] = useImportAudienceMutation();
    const [updateAudenceStatus, {isLoading: UpdateStatusLoading}] = useUpdateAudenceStatusMutation();
    const [deleteAudienceList, {isLoading: DeleteAudienceLoading}] = useDeleteAudienceListMutation();
    const [getFilteredpatient] = useGetFilteredpatientMutation();
    const [getAudience, { isLoading: AudienceLoading }] = useGetAudienceMutation();
    const [addAudience, { isLoading }] = useAddAudienceMutation();
    const [patientCount, setPatientCount] = useState(null);
    const hasRunEffect = useRef(false);
    const navigate = useNavigate();
    const [FilteredPatient, setFilteredPatient] = useState([]);
    const [recordId,setRecordId] = useState()
    const [deleteRecordId,setDeleteRecordId] = useState()
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [dropdownValues, setDropdownValues] = useState({});
    const handleEdit = (key) => {
        navigate("/audienceuser", {
            state: { AudienceId: key },
        });
    }

    const handleAudienceStatus = (preValue, value, record) => {
        setRecordId(record?.key)
        Swal.fire({
            title: "Change Status?",
            text: `Are you sure you want to change the status of this connection to ${value}?`,
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            icon: "info"
        }).then((result) => {
            if (result.isConfirmed) {
                updateAudenceStatus({ id: record?.key, value: { status: value} })
                    .then(() => {
                        setRecordId(record?.key);
                        setDropdownValues((prev) => ({ ...prev, [record.key]: value }));
                        Swal.fire("Status Updated!", "", "success");
                        fetchAudienceData();
                    })
                    .catch(() => {
                        Swal.fire("Unable to update status", "", "error");
                        fetchAudienceData();
                    });
            } else {
                setDropdownValues((prev) => ({ ...prev, [record.key]: preValue }));
            }
        });
    };

    const handleAudienceDelete = (record) =>{
        setDeleteRecordId(record?.key)
        Swal.fire({
          title:"Delete Audience Member?",
          text:  "Deleting this audience list is permanent and cannot be undone. Are you sure you want to proceed?",
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          icon:"info"
        }).then((result) => {
          if (result.isConfirmed) {
            deleteAudienceList(record?.key).then((()=>{
                setDeleteRecordId()
                Swal.fire("Successfully Deleted Audience Connection", "", "success");
                fetchAudienceData();
            })).catch((error)=>{
                setDeleteRecordId()
                Swal.fire("Unable to Delete Audience Connections", "", "error");
                fetchAudienceData();
            })
          }
        });
  }

    const columns = [
        {
            title: 'S.No',
            key: 'serialNumber',
            render: (text, record, index) =>
                (pagination.current - 1) * pagination.pageSize + index + 1,
        },
        { title: 'Audience Name', dataIndex: 'audienceName', key: 'audienceName' },
        { title: 'Vendor', dataIndex: 'vendor', key: 'vendor' },
        { title: 'Type of Campaign', dataIndex: 'typeOfCampaign', key: 'typeOfCampaign' },
        { title: 'Appointment Type', dataIndex: 'appointmentType', key: 'appointmentType' },
        { title: 'Audience Type', dataIndex: 'audienceType', key: 'audienceType' },
        { title: 'List Name', dataIndex: 'listId', key: 'listId' },
        { title: 'Status', 
          dataIndex: 'status', 
          key: 'status', 
          with:"15%",
          render: (text,record) =>(
            <>
            {UpdateStatusLoading && record?.key === recordId ? ( <Spin/>):(
          <Select
          disabled={record?.audienceType==="One Time"}
          value={dropdownValues[record.key] ?? record.status} // Controlled value
          onChange={(value) => {
              const previousValue = dropdownValues[record.key] ?? record.status; // Track previous value
              setDropdownValues((prev) => ({ ...prev, [record.key]: value })); // Temporarily set
              handleAudienceStatus(previousValue, value, record); // Handle change
          }}
          className="audience-status-dropdown"
      >
          <Option value="Live">Live</Option>
          <Option value="Paused">Paused</Option>
      </Select>
            ) }
            
            </>
          )
        },
        {
            title: "View Patient List",
            key: "patient",
            width:"10%",
            render: (text, record) =>
                <Button
                    className="listingButton"
                    style={{ cursor: "pointer", }}
                    onClick={() => handleEdit(record.key)}
                >View List</Button>
        },
        {
            title: "Connection",
            key: "edit",
            render: (text, record) =>
                <>
                {record?.connection_status === "imported" && record?.status === "Paused" && record?.type_of_audience !=="One Time" ? (
                    <Tooltip placement="top" title={"Please Change the status to Live to Refetch Connection."}>
                        <Button
                        disabled
                    className="listingButton"
                    style={{ cursor: "pointer", }}
                >{record?.connection_status==="imported" ? "Refetch Connection" :"Create Connection"}</Button>
                    </Tooltip>
                ) : (
                    <>
                    <Button
                    className="listingButton"
                    style={{ cursor: "pointer", }}
                    onClick={() => handleCreateConnection(record)}
                >{record?.connection_status==="imported" ? "Refetch Connection" :"Create Connection"}</Button></>
                )}
                
                </>
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <>
                {DeleteAudienceLoading && deleteRecordId === record?.key ? (<Spin/>) :(
                    <MdDelete onClick={()=>handleAudienceDelete(record)}
                    style={{ cursor: "pointer", color: '#ff0000' }}
                />
            )}
            </>
                 
        },
    ];

    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        fetchAudienceData();
    }, [pagination.current, pagination.pageSize]);

    const fetchAudienceData = () => {
        getAudience()
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.id,
                    audienceName: item.name,
                    appointmentType: item.appointment_type ? JSON.parse(item.appointment_type).join(', ') : '',
                    audienceType: item.type_of_audience,
                    status: item.status,
                    typeOfCampaign: item.campaign_type,
                    vendor: item.vendor,
                    listId: item.list_id,
                    audienceData: JSON.parse(item.audience),
                    connection_status:item.connection_status
                }));
                setAudienceData(formattedData);
            })
            .catch((err) => {
                console.error("Error fetching audience data:", err);
            });
    };

    const options = [
        { value: "Skin care", label: "Skin Care" },
        { value: "Injectables", label: "Injectables" },
        { value: "Weight Loss", label: "Weight Loss" },
        { value: "Other", label: "Other" },
    ];



    const handleFilterData = (appointType) => {
        if (appointType.includes("All")) {
            const allValues = options.map((option) => option.value);
            setAppointmentType(allValues);
        } else {
            setAppointmentType(appointType);
        }
        if (last_visit_days != null) {
            handledayChange(last_visit_days, appointType)
        }
    }

    const handledayChange = (day, appointType) => {
        setLastVisit(day);
        let query = `?days=${day}&per_page=-1&service_category=${appointType ? appointType : appointment_type}`;
        getFilteredpatient(query).then((res) => {
            const formattedData = res.data.map((item) => ({
                first_name: item.patient_name,
                email: item.patient.email,

            }));
            setPatientCount(res.data.length)
            setFilteredPatient(formattedData);
        }).catch((error) => {
            console.error("Error fetching filtered patients:", error);
        });
        getLists()
            .then((response) => {
                const ids = response.data.lists.map((el) => ({ id: el.list_id, name: el.name }));
                setListIds(ids);
            })
            .catch((error) => {
                console.error("Error fetching filtered patients:", error);
            });
    };

    const handlePaginationChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
        });
    };

    const onPageSizeChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
        });
	};
    const handleCreateAudience = async () => {

        if (patientCount === 0) {

            Swal.fire({
                title: "Patient list not found",
                text: "Patient list count is 0.",
                icon: "info",
                confirmButtonText: "OK"
            })
        } else {
            const audienceDetails = {
                appointment_type,
                last_visit_days,
                status:"Live",
                campaign_type,
                vendor,
                list_id,
                name,
                type_of_audience,
                audience: FilteredPatient
            };

            const response = await addAudience(audienceDetails);

            if (response.error) {
                Swal.fire({
                    title: response.error.data.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                return;
            }
            const { data, message } = response.data
            if (data) {

                setAppointmentType([])
                setLastVisit(null)
                setCampaignType(null)
                setVendor(null)
                setName('')
                setListId(null)
                Swal.fire({
                    title: message,
                    icon: "success",
                });
                fetchAudienceData();
            }
        }

    };
    const handleCreateConnection = async (audienceData) => {
        const payload = {
            import_data: audienceData?.audienceData,
            audience_id:audienceData?.key,
            list_ids: [audienceData?.listId]

        }
        const response = await importAudience(payload);

        if (response.error) {
            Swal.fire({
                title: response.error.data.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        const { data, message } = response.data
        if (data) {
            Swal.fire({
                title: message,
                icon: "success",
            });
            fetchAudienceData();
        }
    }
    return (
        <div className="wrapper orderWrapper container-fluid">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="titleBar d-flex justify-content-between">
                        <h4>Audiences Management</h4>
                    </div>
                </div>
            </div>

            <div className='card dashboardCard mb-4'>
                <div className='card-body'>
                    <div className="row">
                        <div className="titleBar d-flex justify-content-between">
                            <h5>Create Audience</h5>
                        </div>
                        <div className="col-md-3">
                            <div className='mb-3'>
                                <label>Appointment Type</label>
                                <Select
                                    placeholder="Select Appointment Type"
                                    className="formSelect w-100"
                                    mode="multiple"
                                    value={appointment_type}
                                    onChange={handleFilterData}
                                >
                                    <Option value="All">All</Option>
                                    {options.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='mb-3'>
                                <label>Last Visit</label>
                                <Select
                                    placeholder="Select Last Visit"
                                    className="formSelect w-100"
                                    value={last_visit_days}
                                    onChange={(value) => {
                                        handledayChange(value); // Call handledayChange with the selected value
                                    }}
                                >
                                    <Option value="7">7 days</Option>
                                    <Option value="30">30 days</Option>
                                    <Option value="45">45 days</Option>
                                    <Option value="60">60 days</Option>
                                    <Option value="90">90 days</Option>
                                    <Option value="120">120 days</Option>
                                </Select>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="mb-3">
                                <label>Status</label>
                                <Select
                                    placeholder="Select Status"
                                    className="formSelect w-100"
                                    onChange={setStatus}
                                    value={status}
                                >
                                    <Option value="Live">Live</Option>
                                    <Option value="Paused">Paused</Option>
                                    <Option value="Deleted">Delete</Option>
                                </Select>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Type of Campaign</label>
                                <Select
                                    placeholder="Select Campaign Type"
                                    className="formSelect w-100"
                                    onChange={setCampaignType}
                                    value={campaign_type}
                                >
                                    <Option value="email">Email</Option>
                                    <Option value="sms">SMS</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Vendor</label>
                                <Select
                                    placeholder="Select Vendor"
                                    className="formSelect w-100"
                                    onChange={setVendor}
                                    value={vendor}
                                >
                                    <Option value="constant_contact">Constant Contact</Option>

                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>List Name</label>
                                <Select
                                    disabled={ListIds.length === 0}
                                    placeholder="Select List"
                                    className="formSelect w-100"
                                    onChange={setListId}
                                    value={list_id}
                                >
                                    {ListIds.map((list) => (
                                        <Option key={list.id} value={list.id}>{list.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Name</label>
                                <Input
                                    placeholder="Name Audience"
                                    className="formControl w-100"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label>Audience Type</label><br />
                                <Radio.Group className="radio-group-type" onChange={(e) => setAudienceType(e.target.value)} value={type_of_audience}>
                                    <Radio value="Recurring">Recurring</Radio>
                                    <Radio value="One Time">One time</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="col-md-12 my-4">
                            <div className='bottomContentRow'>


                                {patientCount !== null ? (
                                    <p className='resultText'>
                                        Found {patientCount} patient{patientCount > 1 ? 's' : ''}
                                    </p>
                                ) : (<p className='resultText'></p>)}
                                {appointment_type.length > 0 && last_visit_days && campaign_type && vendor && name !== '' ? (
                                    <Button
                                        type="primary"
                                        className="listingButton"
                                        onClick={handleCreateAudience}
                                        loading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Create Audience
                                    </Button>
                                ) : (<></>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tableWrapper'>
                <Table
                    dataSource={audienceData}
                    columns={columns}
                    className="dashboardTable registered-user-table"
                    scroll={{ x: 'scroll' }}
                    loading={AudienceLoading}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: audienceData?.length,
                        onChange: handlePaginationChange,
                        onShowSizeChange: onPageSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                    showSorterTooltip={false}
                    bordered
                />
            </div>
        </div>
    );
};

export default Audience;
