import React, { useState, useEffect, useRef } from "react";
import { Table, Select, Button, Input } from "antd";
import { MdRefresh } from "react-icons/md";
import {
    useGetProductMutation,
    useEditProductMutation,
} from "../../redux/slices/productSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const Inventory = () => {
    const userData = useSelector((state) => state?.persistedReducer?.user);
    const navigate = useNavigate();
    const [loadings, setLoadings] = useState([]);
    const [getProduct, { isLoading }] = useGetProductMutation();
    const [editProduct] = useEditProductMutation();
    const [productData, setproductData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [stockValues, setStockValues] = useState({});
    const hasRunEffect = useRef(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalProducts, setTotalProducts] = useState(0);

    useEffect(() => {
        if (hasRunEffect.current) return;
        hasRunEffect.current = true;
        const query = `?per_page=-1`;
        getProduct(query)
            .then((res) => {
                const formattedData = res.data.map((item) => ({
                    key: item.id,
                    imageUrl: item.image,
                    vendor: item.vendor_name ? item.vendor_name : "N/A",
                    company_name: item.company_name ? item.company_name : "N/A",
                    product_description: item?.product_description,
                    size: item?.size,
                    price: item?.price,
                    in_stock: item?.in_stock,
                    name: item.item_name,
                    category: item.category.name,
                    category_id: item.category_id,
                    stock: item.quantity,
                    size: item.size,
                    created_by:item?.craeted_by,
                    created_by:item?.created_by
                }));
                setproductData(formattedData);
                setFilteredData(formattedData);
                setTotalProducts(res.total);
                const initialStockValues = {};
                formattedData.forEach((item) => {
                    initialStockValues[item.key] = item.stock;
                });
                setStockValues(initialStockValues);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }, [currentPage, pageSize]);

    const handleStockChange = (e, key) => {
        setStockValues({
            ...stockValues,
            [key]: e.target.value,
        });
    };

    const enterLoading = async (item, index) => {
        if(stockValues[item?.key]===''){
            Swal.fire({
                title: "Unable to update product",
                text: "No quantity added.",
                icon: "info",
                confirmButtonText: "OK",
            });
        }else if (item?.stock === parseInt(stockValues[item?.key])) {
            Swal.fire({
                title: "Unable to update product",
                text: "The quantity of product is the same as before and after.",
                icon: "info",
                confirmButtonText: "OK",
            });
        } else {
            let formData = {
                product_id: item?.key,
                vendor_name: item?.vendor,
                company_name: item?.company_name,
                item_name: item?.name,
                product_description: item?.product_description,
                size: item?.size ? item?.size : "N/A",
                price: item?.price,
                in_stock: item?.in_stock === 1 ? true : false,
                quantity:
                    stockValues[item?.key] === ""
                        ? item?.stock
                        : parseInt(stockValues[item?.key]),
                category_id: item?.category_id,
                user_id: userData?.id,
            };
            const response = await editProduct({ productdata: formData });
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = true;
                return newLoadings;
            });
            setTimeout(() => {
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[index] = false;
                    return newLoadings;
                });
            }, 3000);

            if (response?.error) {
                Swal.fire({
                    title: "Product updation failed",
                    text: `${response?.data?.error?.message}`,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            } else {
                Swal.fire({
                    title: "Product updated!",
                    text: `${response?.data?.message}`,
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    };

    const tableColumns = [
        {
            title: "S.No",
            key: "serial_number",
            render: (text, record, index) => (
                <span>{(currentPage - 1) * pageSize + index + 1}</span>
            ),
        },
        {
            title: "Product",
            dataIndex: "companyImg",
            key: "companyImg",
            render: (text, record) => (
                <div
                    className="productBox"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <div className="imgBox" style={{ marginRight: "20px" }}>
                        {record?.imageUrl ? (
                            <img
                                src={record.imageUrl}
                                alt={record.name}
                                className="img-fluid"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                    borderRadius: "5px",
                                }}
                            />
                        ) : (
                            <p className="prouct-image-text">Product Image</p>
                        )}
                    </div>
                    <span style={{ textTransform: "capitalize", fontWeight: "600" }}>
                        {record.name}
                    </span>
                </div>
            ),
        },
        {
            title: "Company Name",
            dataIndex: "company_name",
            key: "company_name",
            // sorter: (a, b) => a.company_name.length - b.company_name.length,
        },
        {
            title: "Vendor name",
            dataIndex: "vendor",
            key: "vendor",
            // sorter: (a, b) => a.vendor.length - b.vendor.length,
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
            // sorter: (a, b) => a.vendor.length - b.vendor.length,
            render: (text, record) => (
                <>
               <span>{record?.created_by ? record?.created_by : "N/A"}</span>
               </>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            // sorter: (a, b) => a.category.length - b.category.length,
        },
        {
            title: "In Stock",
            dataIndex: "stock",
            key: "stock",
            render: (text, record) => (
                <Input
                    type="text"
                    value={stockValues[record.key]}
                    rules={[
                        {
                            validator: (_, value) =>
                                value && value.length <= 5
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error("Quantity must not exceed 5 digits.")
                                    ),
                        },
                    ]}
                    onChange={(e) => handleStockChange(e, record.key)}
                    maxLength={5}
                    onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onPaste={(e) => {
                        const paste = e.clipboardData.getData("text");
                        if (!/^\d{0,5}$/.test(paste)) {
                            e.preventDefault();
                        }
                    }}
                    className="text-center"
                    style={{ width: "150px" }}
                />
            ),
            // sorter: (a, b) => a.stock - b.stock,
        },
        {
            title: "Update Stock",
            dataIndex: "update",
            key: "update",
            render: (text, record, index) => {
                return (
                    <Button
                        type="primary"
                        icon={<MdRefresh />}
                        loading={loadings[index]}
                        onClick={(e) => {
                            e.stopPropagation();
                            enterLoading(record, index);
                        }}
                        className="updateBtn"
                    >
                        Update
                    </Button>
                );
            },
        },
    ];

    const handleNavigate = (record) => {
        navigate("/product-details", {
            state: { productId: record.key },
        });
    };

    const handleCategoryChange = (value) => {
        const filtereddata = productData.filter(
            (item) => item.category_id === value
        );
        setFilteredData(filtereddata);
    };
    //   For searching the elements in the SearchBar
    const handleProductSearch = (event) => {
        const searchText = event.target.value.toLowerCase().trim();

        const filteredData = productData.filter(
            (item) =>
                item.name.toLowerCase().includes(searchText) ||
                item.vendor.toLowerCase().includes(searchText) ||
                item.company_name.toLowerCase().includes(searchText)
        );
        setFilteredData(filteredData);
    };
   
    return (
        <div className="wrapper productCatalogWrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Inventories List</h4>
                            <div className="rightWrapper">
                                <Input
                                    placeholder="Search here"
                                    onChange={handleProductSearch}
                                    className="homesearchInput"
                                />
                                <Select
                                    className="formSelect"
                                    placeholder="Choose Category"
                                    onChange={handleCategoryChange}
                                >
                                    <Select.Option value={1}>Injectable</Select.Option>
                                    <Select.Option value={2}>Supplies</Select.Option>
                                    <Select.Option value={3}>Medications</Select.Option>
                                    <Select.Option value={4}>Skin Care</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredData}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalProducts,
                                    onChange: (page, size) => {
                                        setCurrentPage(page);
                                        setPageSize(size);
                                    },
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: ["5", "10", "20", "50"],
                                }}
                                loading={isLoading}
                                showSorterTooltip={false}
                                onRow={(record) => ({
                                    onClick: () => handleNavigate(record),
                                })}
                                scroll={{ x: "auto" }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inventory;
